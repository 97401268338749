/**
 * AJAX Formspree contact form
 */

export default (() => {
  const form = document.querySelector('.contact-form')

  if (form === null) return

  async function handleSubmit(event) {
    event.preventDefault()

    let contactButton = form.querySelector('button[type="submit"]')
    let contactStatus = form.querySelector('.contact-status')

    let buttonText = contactButton.innerHTML

    let data = new FormData(event.target)

    // Submit request
    contactButton.innerHTML = 'Sending...'
    fetch(event.target.action, {
      method: form.method,
      body: data,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        // Set button text back to normal
        contactButton.innerHTML = buttonText

        // Display content and apply styling to response message
        if (response.ok) {
          contactStatus.classList.remove('status-error')
          contactStatus.classList.add('status-success')
          contactStatus.innerHTML =
            "Thanks for your interest! We'll be in touch shortly!"
        } else {
          contactStatus.classList.remove('status-success')
          contactStatus.classList.add('status-error')
          contactStatus.innerHTML =
            'Oops! There was a problem submitting your form'
        }
      })
      .catch((error) => {
        contactStatus.innerHTML =
          'Oops! There was a problem submitting your form'
        console.log(error)
      })
  }

  form.addEventListener('submit', handleSubmit)
})()
